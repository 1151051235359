import clsx from 'clsx';
import { useCallback, useEffect, useRef, type FC, type ReactNode } from 'react';
import styled from 'styled-components';

import { BoxContent, Header } from 'src/components/common';
import { useLocationContext } from 'src/components/common/providers/location-provider';
import {
  useWorkContentToggleContext,
  useWorkContentToggleDispatchContext,
} from 'src/components/common/providers/work-content-toggle-provider';
import {
  useWorkListToggleContext,
  useWorkListToggleDispatchContext,
} from 'src/components/common/providers/work-list-toggle-provider';
import { media } from 'src/styles';
import { useIsMobile } from 'src/utils';

type Props = {
  className?: string;
  children: ReactNode;
};

function useHandleHideBoxLayer() {
  const isMobile = useIsMobile();
  const { isToggled: workContentIsToggle } = useWorkContentToggleContext();
  const workContentToggleDispatch = useWorkContentToggleDispatchContext();

  const { isToggled: workListIsToggled } = useWorkListToggleContext();
  const workListToggleDispatch = useWorkListToggleDispatchContext();
  const handleHideBoxLayerForBox = useCallback<
    React.PointerEventHandler<HTMLElement>
  >(
    (event) => {
      // NOTE:
      // Box下部のpadding部分の時、
      // event.currentTargetとevent.targetが一致するので、
      // その際にトリガーさせる
      if (isMobile && event.currentTarget === event.target) {
        workContentIsToggle &&
          workContentToggleDispatch &&
          workContentToggleDispatch({ type: 'hide' });
        workListIsToggled &&
          workListToggleDispatch &&
          workListToggleDispatch({ type: 'hide' });
      }
    },
    [
      isMobile,
      workContentIsToggle,
      workContentToggleDispatch,
      workListIsToggled,
      workListToggleDispatch,
    ]
  );
  const handleHideBoxLayerForMain = useCallback<
    React.PointerEventHandler<HTMLElement>
  >(() => {
    if (isMobile) {
      workContentIsToggle &&
        workContentToggleDispatch &&
        workContentToggleDispatch({ type: 'hide' });
      workListIsToggled &&
        workListToggleDispatch &&
        workListToggleDispatch({ type: 'hide' });
    }
  }, [
    isMobile,
    workContentIsToggle,
    workContentToggleDispatch,
    workListIsToggled,
    workListToggleDispatch,
  ]);

  return { handleHideBoxLayerForMain, handleHideBoxLayerForBox };
}

function useBoxRef() {
  const { slug } = useLocationContext();
  const boxRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    // NOTE:
    // ページ遷移時には、
    // box内のscrollTopをリセットする。
    if (boxRef.current) {
      boxRef.current.scrollTop = 0;
    }
  }, [slug]);
  return boxRef;
}

export const Page: FC<Props> = ({ className, children }) => {
  const { handleHideBoxLayerForBox, handleHideBoxLayerForMain } =
    useHandleHideBoxLayer();
  const boxRef = useBoxRef();
  const { slug } = useLocationContext();
  return (
    <Wrapper className={clsx(className)}>
      <Box
        className={slug === 'about' ? 'off-hover-effect' : undefined}
        onPointerDown={handleHideBoxLayerForBox}
        ref={boxRef}
      >
        <BoxInner>
          <Header />
          <BoxContent />
        </BoxInner>
      </Box>
      <Main role="main" id="main" onPointerDown={handleHideBoxLayerForMain}>
        {children}
      </Main>
    </Wrapper>
  );
};

const BoxInner = styled.div`
  background-color: white;
  padding-right: 1px;
  ${media.lessThanIpadVertical`
    padding-top: ${({ theme }) => theme.structure.box.margin.top.sp}px;
  `}
`;

const Box = styled.div`
  position: fixed;
  z-index: 10;
  top: 0;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  max-height: 100%;
  /* widthはspの時は%表示 */
  width: ${({ theme }) => theme.structure.box.width.sp}%;
  /* bottomはmain側に合わせる */
  padding-bottom: ${({ theme }) => theme.structure.main.margin.bottom.sp}px;
  left: ${({ theme }) => theme.structure.box.margin.horizontal.sp}px;

  /* NOTE:
  hoverでscrollbarを表示するのはchromeでは動作するが、
  safariでは動作しないので、とりあえず無しで。
   */
  /* &:hover {
    ::-webkit-scrollbar {
      width: 1px;
    }
    ::-webkit-scrollbar-track {
      border-radius: 0;
    }
    ::-webkit-scrollbar-thumb {
      background-color: black;
      border-radius: 0;
    }
  } */
  ${media.ipadVerticalOrMore`
    z-index: 0;
    width: ${({ theme }) => theme.structure.box.width.pc}px;
    padding-top: ${({ theme }) => theme.structure.box.margin.top.pc}px;
    /* bottomはmain側に合わせる */
    padding-bottom: ${({ theme }) => theme.structure.main.margin.bottom.pc}px;
    left: ${({ theme }) => theme.structure.box.margin.horizontal.pc}px;
    &:hover {
      z-index: 10;
    }
    &.off-hover-effect {
      z-index: 10;
    }
  `}
`;

const Main = styled.main`
  flex-grow: 1;
  /* NOTE:
    ここでposition: relativeを設定するより、
    MainContentの中の各アイテムにposition:relatvieを設定した方が、
    動作的に理想に近いのでそうする。
    もしそれにより問題が発生した場合は、
    素直にここでposition: relativeを設定して、
    MainContentのpaddingをmarginに変更する。
    （Boxへのmouse eventが取得できなくなってしまうため）
   */
  /*
  position: relative;
  z-index: 1;
  */
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-weight: 500;
  font-size: ${({ theme }) => theme.font.size.normal};
  line-height: ${({ theme }) => theme.lineHeight.ja};
  ${media.ipadVerticalOrMore`
    font-size: ${({ theme }) => theme.font.size.normal};
  `}
`;

export default Page;
