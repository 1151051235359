export const theme = {
  font: {
    family: {
      main: 'a-otf-futo-go-b101-pr6n, "Helvetica Neue", "Helvetica", "游ゴシック体", "YuGothic", "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif',
    },
    size: {
      headerSp: '5.3vw',
      large: '2.2rem',
      normalEn: '1.4rem',
      normal: '1.3rem',
    },
  },
  letterSpacing: {
    ja: null,
  },
  lineHeight: {
    ja: 1.95,
    en: 1.71,
  },
  colors: {
    bg: '#fff',
    font: '#000',
    gray: '#9FA0A0',
    lightGray: '#cecece',
  },
  structure: {
    box: {
      margin: {
        horizontal: {
          sp: 7,
          pc: 23,
        },
        top: {
          sp: 7 - 2,
          pc: 17 - 2,
        },
      },
      width: {
        // NOTE:
        // sp 414pxの時に、318px相当
        sp: 77,
        pc: 360,
      },
    },
    main: {
      margin: {
        horizontal: {
          sp: 48,
          // NOTE:
          // pcは% 1440pxの時、252px相当（縦画像を基準にする）
          pc: 17.5,
        },
        top: {
          sp: 124,
          pc: 120,
        },
        bottom: {
          sp: 60,
          pc: 120,
        },
      },
    },
  },
  mobileMenuListItemHeight: 220,
  breakpoints: {
    iphoneVertical: 375,
    ipadVertical: 768,
    ipadHorizontal: 1024,
    ipadProHorizontal: 1300,
    large: 1440,
  },
};

type ThemeType = typeof theme;

declare module 'styled-components' {
  export interface DefaultTheme extends ThemeType {}
}

export default theme;
