import { createContext, useContext, type ReactNode } from 'react';

const initialValue = {
  slug: '',
  pathname: '',
  isHome: true,
};

type Props = {
  pathname: string;
  children: ReactNode;
};

type ContextType = typeof initialValue;

const SlugContext = createContext<ContextType>(initialValue);

function getSlug({ pathname }: { pathname: string }) {
  if (pathname === '/') {
    return 'home';
  } else {
    return pathname.replace(/^\/?([^/]+)\/?$/, '$1');
  }
}

export const LocationProvider = ({ pathname, children }: Props) => {
  const slug = getSlug({ pathname });
  return (
    <SlugContext.Provider value={{ slug, pathname, isHome: pathname === '/' }}>
      {children}
    </SlugContext.Provider>
  );
};

export const useLocationContext = () => {
  return useContext(SlugContext);
};
