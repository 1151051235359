import clsx from 'clsx';
import type { FC, ReactNode } from 'react';
import styled from 'styled-components';

type Props = {
  className?: string;
  children: ReactNode;
};

export const Japanese: FC<Props> = ({ className, children }) => (
  <Wrapper className={clsx(className)}>{children}</Wrapper>
);

const Wrapper = styled.div`
  font-size: ${({ theme }) => theme.font.size.normal};
  line-height: ${({ theme }) => theme.lineHeight.ja};
`;

export default Japanese;
