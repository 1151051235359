import {
  createContext,
  useContext,
  useReducer,
  type Dispatch,
  type ReactNode,
  type Reducer,
} from 'react';

const initialValue = {
  content: null as ReactNode,
};

type Props = {
  children: ReactNode;
};

type ContextType = typeof initialValue;
type ReducerType = { type: string; payload?: ContextType };
type DispatchContextType = {
  dispatch: Dispatch<ReducerType> | null;
};

const Context = createContext<ContextType>(initialValue);

const DispatchContext = createContext<DispatchContextType>({
  dispatch: null,
});

const reducer: Reducer<ContextType, ReducerType> = (state, action) => {
  switch (action.type) {
    case 'set':
      return { ...state, ...action.payload };
    case 'remove':
      return { ...state, ...{ content: null } };
    default:
      throw new Error('BoxContentReducer error');
  }
};

export const BoxContentProvider = ({ children }: Props) => {
  const [state, dispatch] = useReducer(reducer, initialValue);
  return (
    <Context.Provider value={state}>
      <DispatchContext.Provider value={{ dispatch }}>
        {children}
      </DispatchContext.Provider>
    </Context.Provider>
  );
};

export const useBoxContentContext = () => {
  return useContext(Context);
};

export const useBoxContentDispatchContext = () => {
  const { dispatch } = useContext(DispatchContext);
  return dispatch;
};
