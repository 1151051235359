import clsx from 'clsx';
import type { FC } from 'react';
import styled from 'styled-components';

import { WorkList } from 'src/components/common';
import { useBoxContentContext } from 'src/components/common/providers/box-content-provider';
import { useWorkListToggleContext } from 'src/components/common/providers/work-list-toggle-provider';
import { media } from 'src/styles';

type Props = {
  className?: string;
};

export const BoxContent: FC<Props> = ({ className }) => {
  const { isToggled: isWorkListToggled } = useWorkListToggleContext();
  const { content } = useBoxContentContext();
  if (!isWorkListToggled && !content) {
    return null;
  }
  return (
    <Wrapper id="box-content" className={clsx(className)}>
      {isWorkListToggled && <WorkList />}
      {!isWorkListToggled && content && content}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  /* NOTE:
    iPhoneX系(375px)はpadding-topは必要ないが、
    それ以上のmobileは少しpadding-topを追加する。
   */
  ${media.greaterThanIphoneVertical`
    padding-top: 3px;
  `}
  ${media.ipadVerticalOrMore`
    padding-top: 122px;
  `}
`;

export default BoxContent;
