import clsx from 'clsx';
import type { FC, ReactNode } from 'react';
import { media } from 'src/styles';
import styled from 'styled-components';

type Props = {
  className?: string;
  isNarrow?: boolean;
  children?: ReactNode;
};

export const MainContent: FC<Props> = ({
  className,
  isNarrow = false,
  children,
}) => (
  <Wrapper className={clsx(className, { narrow: isNarrow })}>
    {children}
  </Wrapper>
);

const Wrapper = styled.div`
  padding-top: ${({ theme }) => theme.structure.main.margin.top.sp}px;
  padding-bottom: ${({ theme }) => theme.structure.main.margin.bottom.sp}px;
  &:not(.narrow) {
    padding-left: ${({ theme }) => theme.structure.box.margin.horizontal.sp}px;
    padding-right: ${({ theme }) => theme.structure.box.margin.horizontal.sp}px;
  }
  &.narrow {
    padding-left: ${({ theme }) => theme.structure.main.margin.horizontal.sp}px;
    padding-right: ${({ theme }) =>
      theme.structure.main.margin.horizontal.sp}px;
  }
  > * {
    position: relative;
  }
  ${media.ipadVerticalOrMore`
    padding-top: ${({ theme }) => theme.structure.main.margin.top.pc}px;
    padding-bottom: ${({ theme }) => theme.structure.main.margin.bottom.pc}px;
    &:not(.narrow) {
      padding-left: ${({ theme }) =>
        theme.structure.box.margin.horizontal.pc}px;
      padding-right: ${({ theme }) =>
        theme.structure.box.margin.horizontal.pc}px;
    }
    &.narrow {
      /* NOTE:
        pcの場合はhorizontalは%
      */
      padding-left: ${({ theme }) =>
        theme.structure.main.margin.horizontal.pc}%;
      padding-right: ${({ theme }) =>
        theme.structure.main.margin.horizontal.pc}%;
    }
  `}
`;

export default MainContent;
