import { type FC, type ReactNode } from 'react';
import { ThemeProvider } from 'styled-components';

import { theme as styleTheme } from 'src/styles';

import { BoxContentProvider } from './box-content-provider';
import { LocationProvider } from './location-provider';
import { WorkContentToggleProvider } from './work-content-toggle-provider';
import { WorkListToggleProvider } from './work-list-toggle-provider';

type Props = {
  pathname: string;
  children: ReactNode;
};

export const Providers: FC<Props> = ({ pathname, children }) => {
  return (
    <ThemeProvider theme={styleTheme}>
      <LocationProvider pathname={pathname}>
        <WorkListToggleProvider pathname={pathname}>
          <WorkContentToggleProvider>
            <BoxContentProvider>{children}</BoxContentProvider>
          </WorkContentToggleProvider>
        </WorkListToggleProvider>
      </LocationProvider>
    </ThemeProvider>
  );
};

export default Providers;
