import clsx from 'clsx';
import type { FC } from 'react';
import styled from 'styled-components';

import { Navigation } from './navigation';
import { Title } from './title';

type Props = {
  className?: string;
};

export const Header: FC<Props> = ({ className }) => (
  <Wrapper className={clsx(className)} role="banner">
    <StyledTitle />
    <StyledNavigation />
  </Wrapper>
);

const StyledTitle = styled(Title)``;

const StyledNavigation = styled(Navigation)``;

const Wrapper = styled.header`
  background-color: white;
`;

export default Header;
