import { forwardRef, type ComponentProps } from 'react';
import styled, { css } from 'styled-components';

// https://make.wordpress.org/accessibility/handbook/markup/the-css-class-screen-reader-text/
type Props = Omit<ComponentProps<'span'>, 'ref'>;

export const ScreenReaderText = forwardRef<HTMLSpanElement, Props>(
  ({ children, ...rest }, ref) => (
    <Wrapper ref={ref} {...rest}>
      {children}
    </Wrapper>
  )
);

ScreenReaderText.displayName = 'ScreenReaderText';

export const ScreenReaderTextStyle = css`
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  word-wrap: normal !important;
  &:focus {
    background-color: #eee;
    clip: auto !important;
    clip-path: none;
    color: #444;
    display: block;
    font-size: 1em;
    height: auto;
    left: 5px;
    line-height: normal;
    padding: 15px 23px 14px;
    text-decoration: none;
    top: 5px;
    width: auto;
    z-index: 100000; /* Above WP toolbar. */
  }
`;

const Wrapper = styled.span`
  ${ScreenReaderTextStyle}
`;

export default ScreenReaderText;
