import clsx from 'clsx';
import { forwardRef, type ComponentProps } from 'react';
import styled from 'styled-components';

type Props = Omit<ComponentProps<'button'>, 'ref'>;
// NOTE:
// refでoverloadのerrorが出るので、
// omitする

export const Button = forwardRef<HTMLButtonElement, Props>(
  ({ className, onClick, children, disabled, type, ...rest }, ref) => (
    <Wrapper
      ref={ref}
      className={clsx(className)}
      onClick={onClick}
      disabled={disabled}
      type={type ?? 'button'}
      {...rest}
    >
      {children}
    </Wrapper>
  )
);

Button.displayName = 'Button';

const Wrapper = styled.button`
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  color: inherit;
  font: inherit;
  line-height: normal;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
  &[disabled] {
    opacity: 0.3;
    pointer-events: none;
  }
`;
