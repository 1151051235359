import clsx from 'clsx';
import type { FC } from 'react';
import styled from 'styled-components';

import { ScreenReaderText } from 'src/components/common';
import { Anchor } from 'src/components/common/atoms';
import { useLocationContext } from 'src/components/common/providers/location-provider';
import RegisteredTrademarkSVG from 'src/images/registered_trademark.svg';
import { media } from 'src/styles';

type Props = {
  className?: string;
};

export const Title: FC<Props> = ({ className }) => {
  const { isHome } = useLocationContext();
  return (
    <Wrapper className={clsx(className)} as={isHome ? 'h1' : 'p'}>
      <StyledAnchor to={`/`} lang="en">
        Rikako Nagashima (village
        <RegisteredTrademark>
          <ScreenReaderText>&reg;</ScreenReaderText>
          <RegisteredTrademarkSVG />
        </RegisteredTrademark>
        )
      </StyledAnchor>
    </Wrapper>
  );
};

const RegisteredTrademark = styled.span`
  svg {
    vertical-align: bottom;
    /* NOTE:
      widthだと調整しにくいので、
      heightで調整
     */
    width: auto;
    height: 0.83em;
    margin-left: 0.08em;
  }
`;

const StyledAnchor = styled(Anchor)`
  text-decoration: none;
  display: block;
  &:hover,
  &:focus {
    text-decoration: none;
  }
`;

const Wrapper = styled.h1`
  line-height: 1;
  font-size: ${({ theme }) => theme.font.size.headerSp};
  font-size: clamp(
    ${({ theme }) => theme.font.size.normal},
    ${({ theme }) => theme.font.size.headerSp},
    ${({ theme }) => theme.font.size.large}
  );
  ${media.ipadVerticalOrMore`
    font-size: ${({ theme }) => theme.font.size.large};
  `}
`;

export default Title;
