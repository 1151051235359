import {
  createContext,
  useContext,
  useLayoutEffect,
  useReducer,
  type Dispatch,
  type ReactNode,
  type Reducer,
} from 'react';

import { useIsMobile } from 'src/utils';

const initialValue = {
  isToggled: false,
};

type ContextType = typeof initialValue;
type ReducerType = { type: string; payload?: ContextType };
type DispatchContextType = {
  dispatch: Dispatch<ReducerType> | null;
};

const Context = createContext<ContextType>(initialValue);

const DispatchContext = createContext<DispatchContextType>({
  dispatch: null,
});

const reducer: Reducer<ContextType, ReducerType> = (state, action) => {
  const { isToggled } = state;
  switch (action.type) {
    case 'set':
      return { ...state, ...action.payload };
    case 'toggle':
      return { ...state, ...{ isToggled: !isToggled } };
    case 'show':
      return { ...state, ...{ isToggled: true } };
    case 'hide':
      return { ...state, ...{ isToggled: false } };
    default:
      throw new Error('WorkListToggleReducer error');
  }
};

type Props = {
  pathname: string;
  children: ReactNode;
};

export const WorkListToggleProvider = ({ pathname, children }: Props) => {
  const isMobile = useIsMobile();
  const [state, dispatch] = useReducer(reducer, initialValue);
  useLayoutEffect(() => {
    const isHome = pathname === '/';
    if (isHome && !isMobile) {
      dispatch({ type: 'show' });
    } else {
      dispatch({ type: 'hide' });
    }
  }, [pathname, isMobile]);
  return (
    <Context.Provider value={state}>
      <DispatchContext.Provider value={{ dispatch }}>
        {children}
      </DispatchContext.Provider>
    </Context.Provider>
  );
};

export const useWorkListToggleContext = () => {
  return useContext(Context);
};

export const useWorkListToggleDispatchContext = () => {
  const { dispatch } = useContext(DispatchContext);
  return dispatch;
};
