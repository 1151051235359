import {
  createContext,
  useContext,
  useLayoutEffect,
  useReducer,
  type Dispatch,
  type ReactNode,
  type Reducer,
} from 'react';

const initialValue = {
  isToggled: false,
};

type ContextType = typeof initialValue;
type ReducerType = { type: string; payload?: ContextType };
type DispatchContextType = {
  dispatch: Dispatch<ReducerType> | null;
};

const Context = createContext<ContextType>(initialValue);

const DispatchContext = createContext<DispatchContextType>({
  dispatch: null,
});

const reducer: Reducer<ContextType, ReducerType> = (state, action) => {
  const { isToggled } = state;
  switch (action.type) {
    case 'set':
      return { ...state, ...action.payload };
    case 'toggle':
      return { ...state, ...{ isToggled: !isToggled } };
    case 'show':
      return { ...state, ...{ isToggled: true } };
    case 'hide':
      return { ...state, ...{ isToggled: false } };
    default:
      throw new Error('WorkContentToggleReducer error');
  }
};

type Props = {
  children: ReactNode;
};

export const WorkContentToggleProvider = ({ children }: Props) => {
  const [state, dispatch] = useReducer(reducer, initialValue);
  useLayoutEffect(() => {
    dispatch({ type: 'show' });
  }, []);
  return (
    <Context.Provider value={state}>
      <DispatchContext.Provider value={{ dispatch }}>
        {children}
      </DispatchContext.Provider>
    </Context.Provider>
  );
};

export const useWorkContentToggleContext = () => {
  return useContext(Context);
};

export const useWorkContentToggleDispatchContext = () => {
  const { dispatch } = useContext(DispatchContext);
  return dispatch;
};
