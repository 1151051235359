import Player, { type Options } from '@vimeo/player';
import clsx from 'clsx';
import { startTransition, useEffect, useRef, useState, type FC } from 'react';
import { useInView } from 'react-intersection-observer';
import sytled from 'styled-components';

type Props = {
  className?: string;
  videoClassName?: string;
  id: string;
  options?: Omit<Options, 'id'>;
  onPlay?: () => void;
};

const useCreateVideo = ({
  id,
  options,
  onPlay,
}: Pick<Props, 'id' | 'options' | 'onPlay'>) => {
  const videoRef = useRef<HTMLDivElement>(null);
  const playerRef = useRef<Player>();
  const [isReadyStartPlaying, setIsReadyStartPlaying] = useState(false);
  const [aspectRatioClassName, setAspectRatioClassName] =
    useState('horizontal');
  useEffect(() => {
    if (videoRef.current) {
      const idNumber = parseInt(id, 10);
      playerRef.current = new Player(videoRef.current, {
        id: idNumber,
        responsive: true,
        playsinline: true,
        byline: false,
        portrait: false,
        loop: true,
        muted: options?.autoplay ? true : false,
        ...options,
      });
      const player = playerRef.current;
      player
        .ready()
        .then(() => {
          startTransition(() => {
            setIsReadyStartPlaying(true);
          });
        })
        .catch((e) => console.log(e));
      Promise.all([player.getVideoWidth(), player.getVideoHeight()])
        .then(([width, height]) => {
          if (width > height) {
            setAspectRatioClassName('horizontal');
          } else if (height > width) {
            setAspectRatioClassName('vertical');
          } else {
            setAspectRatioClassName('square');
          }
        })
        .catch((e) => console.log(e));
      if (onPlay) {
        player.on('play', onPlay);
      }
      return () => {
        if (onPlay) {
          player.off('play', onPlay);
        }
      };
    }
  }, [id, options, onPlay]);

  return { videoRef, playerRef, isReadyStartPlaying, aspectRatioClassName };
};

const useAutoPlay = ({
  isAutoPlay,
  player,
  isReadyStartPlaying,
}: {
  isAutoPlay?: boolean;
  player?: Player;
  isReadyStartPlaying: boolean;
}) => {
  const { ref: iORef } = useInView({
    onChange: (inView) => {
      if (isAutoPlay && player && isReadyStartPlaying) {
        if (inView) {
          player.play().catch((e) => console.log(e));
        } else {
          player.pause().catch((e) => console.log(e));
        }
      }
    },
  });

  return { iORef };
};

export const Video: FC<Props> = ({
  className,
  videoClassName,
  id,
  options,
  onPlay,
}) => {
  const { videoRef, playerRef, isReadyStartPlaying, aspectRatioClassName } =
    useCreateVideo({
      id,
      options,
      onPlay,
    });
  const { iORef } = useAutoPlay({
    isAutoPlay: options?.autoplay,
    player: playerRef?.current,
    isReadyStartPlaying,
  });

  return (
    <Wrapper
      className={clsx(
        'video',
        {
          'is-ready-start-playing': isReadyStartPlaying,
          'is-off-controls': options?.controls === false,
        },
        aspectRatioClassName,
        className
      )}
      ref={iORef}
    >
      <div className={clsx('video-player', videoClassName)} ref={videoRef} />
    </Wrapper>
  );
};

const Wrapper = sytled.div`
  border: 1px solid black;
  background-color: white;
  &.is-ready-start-playing {
    border: none;
  }
  &.is-off-controls {
    pointer-events: none;
  }
`;

export default Video;
