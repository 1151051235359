import clsx from 'clsx';
import { graphql } from 'gatsby';
import type { FC } from 'react';

import { Anchor } from 'src/components/common/atoms';
import styled from 'styled-components';

type Props = Queries.WorkListItemFragment & {
  className?: string;
};

export const fragment = graphql`
  fragment WorkListItem on ContentfulWork {
    slug
    title
  }
`;

export const WorkListItem: FC<Props> = ({ className, slug, title }) => (
  <Wrapper className={clsx(className)}>
    <StyledAnchor to={slug ? `/${slug}/` : '/'}>{title}</StyledAnchor>
  </Wrapper>
);

const StyledAnchor = styled(Anchor)`
  text-decoration: none;
  &.current {
    text-decoration: line-through;
  }
`;

const Wrapper = styled.li`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export default WorkListItem;
