import clsx from 'clsx';
import type { FC } from 'react';
import styled, { css } from 'styled-components';

import { Anchor } from 'src/components/common/atoms';
import { useWorkListToggleContext } from 'src/components/common/providers/work-list-toggle-provider';
import { WorkListButton } from './work-list-button';

export type Props = {
  className?: string;
  name: string;
  label: string;
  href?: string;
  isJapanese?: boolean;
};

export const MenuListItem: FC<Props> = ({
  className,
  name,
  label,
  href,
  isJapanese,
}) => {
  const { isToggled } = useWorkListToggleContext();
  return (
    <Wrapper className={clsx(className)}>
      {name === 'work' && <StyledWorkListButton label={label} />}
      {name !== 'work' && href && (
        <StyledAnchor
          className={clsx(isJapanese && 'language-ja')}
          activeClassName={clsx({ current: !isToggled })}
          to={href}
        >
          {label}
        </StyledAnchor>
      )}
    </Wrapper>
  );
};

const CustomLineThrough = css`
  &::after {
    content: '';
    display: block;
    position: absolute;
    z-index: 1;
    top: 55%;
    left: 0;
    width: 100%;
    border-bottom: 1px solid black;
  }
`;
const BaseStyleClickable = css`
  position: relative;
  &.current {
    ${CustomLineThrough}
  }

  /*
    NOTE:
    mobileの際の、クリックした後にhoverのスタイルが残る問題に対応
    https://css-tricks.com/solving-sticky-hover-states-with-media-hover-hover/
    */
  @media (hover: hover) {
    &:hover {
      ${CustomLineThrough}
    }
  }
`;

const StyledWorkListButton = styled(WorkListButton)`
  ${BaseStyleClickable};
  cursor: pointer;
`;

const StyledAnchor = styled(Anchor)`
  text-decoration: none;
  display: inline-block;
  line-height: 1;
  &:hover,
  &:focus {
    text-decoration: none;
  }
  ${BaseStyleClickable};
  &.language-ja {
    font-size: 0.85em;
  }
`;

const Wrapper = styled.li``;

export default MenuListItem;
