import clsx from 'clsx';
import { useCallback, type FC } from 'react';

import { Button } from 'src/components/common/atoms';
import {
  useWorkListToggleContext,
  useWorkListToggleDispatchContext,
} from 'src/components/common/providers/work-list-toggle-provider';

type Props = {
  className?: string;
  label: string;
};

function useOnClick() {
  const { isToggled } = useWorkListToggleContext();
  const dispatch = useWorkListToggleDispatchContext();
  const onClick = useCallback(() => {
    dispatch && dispatch({ type: 'toggle' });
  }, [dispatch]);

  return { isToggled, onClick };
}

export const WorkListButton: FC<Props> = ({ className, label }) => {
  const { isToggled, onClick } = useOnClick();
  return (
    <Button
      className={clsx(className, { current: isToggled })}
      onClick={onClick}
      aria-expanded={isToggled}
      aria-pressed={isToggled}
      aria-label={'archive button'}
      aria-controls={'header-work-list'}
    >
      {label}
    </Button>
  );
};

export default WorkListButton;
