import {
  type NodeRenderer,
  type Options,
} from '@contentful/rich-text-react-renderer';
import { INLINES } from '@contentful/rich-text-types';
import {
  renderRichText,
  type ContentfulRichTextGatsbyReference,
  type RenderRichTextData,
} from 'gatsby-source-contentful/rich-text';
import { useCallback, useMemo, type FC } from 'react';

import { Anchor } from 'src/components/common/atoms';

type RichTextDocumenType =
  RenderRichTextData<ContentfulRichTextGatsbyReference>;
type CustomRichTextDocumentType = {
  raw: RichTextDocumenType['raw'] | null;
  readonly references?: Queries.Maybe<
    ReadonlyArray<Queries.Maybe<ContentfulRichTextGatsbyReference>>
  >;
};
type Props = {
  document: CustomRichTextDocumentType;
};

const useOptions = () => {
  const linkResolver = useCallback<NodeRenderer>((node, children) => {
    const { data } = node;
    return <Anchor to={data.uri}>{children}</Anchor>;
  }, []);

  const assetLinkResolver = useCallback<NodeRenderer>((node, children) => {
    const { target } = node.data;
    const href = target?.file?.url as string | undefined;
    if (!href) {
      return;
    }
    return (
      <a href={`https:${href}`} target="_blank" rel="noopenner noreferrer">
        {children}
      </a>
    );
  }, []);

  return useMemo<Options>(
    () => ({
      renderNode: {
        [INLINES.HYPERLINK]: linkResolver,
        [INLINES.ENTRY_HYPERLINK]: linkResolver,
        [INLINES.ASSET_HYPERLINK]: assetLinkResolver,
      },
      // NOTE:
      // 改行を<br>に変換
      // https://github.com/contentful/rich-text/issues/96
      renderText: (text) =>
        text
          .split('\n')
          .flatMap((text, i) => [
            i > 0 && <br key={`line-break-${i}`} />,
            text,
          ]),
    }),
    [linkResolver, assetLinkResolver]
  );
};

export const RichText: FC<Props> = ({ document }) => {
  const options = useOptions();
  if (!document?.raw) {
    return null;
  }
  return <>{renderRichText(document as RichTextDocumenType, options)}</>;
};

export default RichText;
