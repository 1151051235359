import clsx from 'clsx';
import { graphql, useStaticQuery } from 'gatsby';
import type { FC } from 'react';
import styled from 'styled-components';

import { WorkListItem } from './work-list-item';

type Props = {
  className?: string;
};

const query = graphql`
  query WorksInHeader {
    allContentfulWork(sort: {date: DESC}) {
      nodes {
        id
        ...WorkListItem
      }
    }
  }
`;

export const WorkList: FC<Props> = ({ className }) => {
  const { allContentfulWork } =
    useStaticQuery<Queries.WorksInHeaderQuery>(query);
  const { nodes } = allContentfulWork;
  return (
    <Wrapper
      id="header-work-list"
      className={clsx(className)}
      aria-label="Work list"
    >
      {nodes && nodes.map((work) => <WorkListItem key={work.id} {...work} />)}
    </Wrapper>
  );
};

const Wrapper = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: ${({ theme }) => theme.font.size.normal};
`;

export default WorkList;
