import clsx from 'clsx';
import { Fragment, type FC } from 'react';
import styled from 'styled-components';

import { MenuListItem, Props as MenuListItemProps } from './menu-list-item';

type Props = {
  className?: string;
  menus: MenuListItemProps[];
};

export const MenuList: FC<Props> = ({ className, menus }) => (
  <Wrapper className={clsx(className)}>
    {menus.map((menu, index) => (
      <Fragment key={`header-menu-${index}`}>
        {index !== 0 && <Slash aria-hidden={true}>/</Slash>}
        <MenuListItem {...menu} />
      </Fragment>
    ))}
  </Wrapper>
);

const Slash = styled.li`
  margin-left: 0.3em;
  margin-right: 0.3em;
`;

const Wrapper = styled.ul`
  list-style: none;
  padding: 0;
  display: flex;
`;

export default MenuList;
