import clsx from 'clsx';
import { GatsbyImage, type GatsbyImageProps } from 'gatsby-plugin-image';
import { useCallback, useMemo, useState, type FC } from 'react';
import { useInView } from 'react-intersection-observer';
import styled from 'styled-components';

import { useIsMobile } from 'src/utils';

type Props = GatsbyImageProps & {
  className?: string;
  loading?: 'eager' | 'lazy';
  isCustomLazyLoading?: boolean;
  onLoad?: () => void;
};

const useLoaded = (onLoad: Props['onLoad']) => {
  const [loaded, setLoaded] = useState(false);
  const handleLoad = useCallback(() => {
    setLoaded(true);
    onLoad && onLoad();
  }, [onLoad]);
  return { loaded, handleLoad };
};

const useAspectRatioClass = (image: Props['image']) =>
  useMemo(() => {
    const aspectRatio = image.width / image.height;
    return aspectRatio > 1
      ? 'horizontal'
      : aspectRatio < 1
      ? 'vertical'
      : 'square';
  }, [image]);

const useCustomLazyLoading = (
  isCustomLazyLoading: Props['isCustomLazyLoading']
) => {
  const isMobile = useIsMobile();
  const { ref: iORef, entry } = useInView({
    triggerOnce: true,
    rootMargin: '5000px',
    onChange: (inView) => {
      if (!isMobile && inView && isCustomLazyLoading) {
        // NOTE:
        // https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-plugin-image/#shared-props
        // GatsbyPluginImageのloadingを設定できるのは、「before React hydration」
        // とのことなので、後でloadingを変更しても反映されないので、
        // DOMから直接loadingを変更する。
        // react-intersection-observerのrefではref.currentからDOMを取得できないので、
        // entry経由でDOMを取得する。
        if (entry?.target) {
          const imgDOM = entry.target.querySelector('img');
          if (imgDOM) {
            imgDOM.setAttribute('loading', 'eager');
          }
        }
      }
    },
  });
  return { iORef };
};

export const Img: FC<Props> = ({
  className,
  image,
  loading = 'lazy',
  isCustomLazyLoading = true,
  onLoad,
  ...rest
}) => {
  const { loaded, handleLoad } = useLoaded(onLoad);
  const aspectRatioClass = useAspectRatioClass(image);
  const { iORef } = useCustomLazyLoading(isCustomLazyLoading);
  return (
    <Wrapper
      className={clsx(className, aspectRatioClass, { loaded: loaded })}
      ref={iORef}
    >
      <GatsbyImage
        image={image}
        loading={loading}
        style={{ width: '100%' }}
        imgStyle={{ transition: 'none' }}
        onLoad={handleLoad}
        {...rest}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  &:not(.loaded) {
    outline: 1px solid black;
    background-color: white;
    > div {
      opacity: 0;
    }
  }
`;

export default Img;
