import clsx from 'clsx';
import type { FC, ReactNode } from 'react';
import styled from 'styled-components';

type Props = {
  className?: string;
  children: ReactNode;
};

export const English: FC<Props> = ({ className, children }) => (
  <Wrapper className={clsx(className)} lang="en">
    {children}
  </Wrapper>
);

const Wrapper = styled.div`
  font-size: ${({ theme }) => theme.font.size.normalEn};
  line-height: ${({ theme }) => theme.lineHeight.en};
`;

export default English;
