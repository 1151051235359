import { PageProps } from 'gatsby';
import type { FC } from 'react';

import { Page, Providers } from 'src/components/common';
import { GlobalStyle } from 'src/styles';
import type { PageContextType } from 'src/types/custom-types';

type Props = PageProps<any, PageContextType>;

const Layouts: FC<Props> = ({ location, children }) => {
  const { pathname } = location;
  return (
    <>
      <GlobalStyle />
      <Providers pathname={pathname}>
        <Page>{children}</Page>
      </Providers>
    </>
  );
};

export default Layouts;
